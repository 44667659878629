import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "../styles/Navbar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../images/CINEMATE-LOGO.png";

const Navbar = ({ authToken, logout }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const handleNavLinkClick = () => {
    setIsExpanded(false);
  };

  const toggleNavbar = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <nav
      className={`navbar navbar-expand-lg fixed-top ${
        isScrolled ? "scrolled" : ""
      } ${isExpanded ? "expanded" : ""}`}
    >
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="logo" className="navbar-logo" />
        </Link>
        <button
          className="navbar-toggler ml-auto"
          type="button"
          aria-controls="navbarNav"
          aria-expanded={isExpanded ? "true" : "false"}
          aria-label="Toggle navigation"
          onClick={toggleNavbar}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isExpanded ? "show" : ""}`} id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink className="nav-link" exact to="/" onClick={handleNavLinkClick}>
                HOME
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/about" onClick={handleNavLinkClick}>
                ABOUT
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/work" onClick={handleNavLinkClick}>
                PORTFOLIO
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/services" onClick={handleNavLinkClick}>
                SERVICES
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/contact-us" onClick={handleNavLinkClick}>
                CONTACT
              </NavLink>
            </li>
            {authToken && (
              <>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/admin" onClick={handleNavLinkClick}>
                    ADMIN
                  </NavLink>
                </li>
                <li className="nav-item">
                  <button
                    className="nav-link btn btn-link"
                    onClick={() => {
                      handleLogout();
                      handleNavLinkClick();
                    }}
                  >
                    LOG-OUT
                  </button>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
