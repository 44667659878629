import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import '../styles/ContactUs.css';

const ContactUs = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
    
        const templateParams = {
            from_name: name,
            from_email: email,
            message: message,
            to_email: 'info@cinemate-productions.com', // Replace with your email address
        };
    
        // Send main email to the professional email
        emailjs.send('service_f2gpiua', 'template_vq59s68', templateParams, 'np8cR5t7hAqYiYqu2')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
    
                // Send confirmation email to the user
                const confirmationParams = {
                    to_name: name,
                    to_email: email,
                    message: message,
                };
    
                emailjs.send('service_f2gpiua', 'template_l3pkm0h', confirmationParams, 'np8cR5t7hAqYiYqu2')
                    .then((confirmResponse) => {
                        console.log('Confirmation Email SUCCESS!', confirmResponse.status, confirmResponse.text);
                        setStatus('SUCCESS');
                        setName('');
                        setEmail('');
                        setMessage('');
                    }, (confirmError) => {
                        console.log('Confirmation Email FAILED...', confirmError);
                        setStatus('FAILED');
                    });
            }, (error) => {
                console.log('FAILED...', error);
                setStatus('FAILED');
            });
    };
    

    return (
        <div className="contact-us-container">
            <div className="contact-us-content">
                <h3 className="contact-us-title">CONTACT US</h3>
                {status === 'SUCCESS' && <p className="success">Message sent successfully!</p>}
                {status === 'FAILED' && <p className="error">Failed to send message. Please try again later.</p>}
                <form onSubmit={handleSubmit}>
                    <div className="form-group-row">
                        <div className="form-group">
                            <label htmlFor="name">Name:</label>
                            <input
                                type="text"
                                id="name"
                                placeholder='Enter your name'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email:</label>
                            <input
                                type="email"
                                id="email"
                                placeholder='Enter your email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Message:</label>
                        <textarea
                            id="message"
                            placeholder='wanna work together? contact us..'
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                        ></textarea>
                    </div>
                    <button type="submit" className="submit-button">Submit</button>
                </form>
                
                <div className="contact-info">
                    <p><span className="icon email-icon"></span>Email: <a href="mailto:info@cinemate-productions.com">info@cinemate-productions.com</a></p>
                    <p><span className="icon phone-icon"></span>Phone Number: <a href="tel:+4917669624139">+4917669624139</a></p>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
