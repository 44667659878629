import React from 'react';
// import VideoCarousel from './VideoCarousel';
import ComingSoon from './ComingSoon';

const Work = () => {
    return (
        <div>
            < ComingSoon     />
        </div>
    );
}

export default Work;